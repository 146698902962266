import { connect } from 'react-redux';
import Component from './LoginForm';
import { IAppState } from '../../../types/state/IAppState';
import { userAuthenticate } from '../../../store/user/actions';
import { getUserAuthToken } from '../../../store/user/selectors';
import { TUserAuthTokenType } from '../../../types/user/IUser';

export interface IAuthUser {
  username: string;
  password: string;
}

export interface ILoginFormProps {
  userAuthToken: TUserAuthTokenType;
  userAuthenticate: any;
}

const mapStateToProps = (state: IAppState) => ({
  userAuthToken: getUserAuthToken(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  userAuthenticate({ username, password }: IAuthUser) {
    return dispatch(userAuthenticate(username, password));
  },
});

const LoginForm = connect(mapStateToProps, mapDispatchToProps)(Component);

export default LoginForm;
