import React, { useEffect } from 'react';
import { Grid, TextField, Button, Box } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { navigate } from 'gatsby';
import { ILoginFormProps, IAuthUser } from './index';

const LoginForm = ({
  userAuthToken,
  userAuthenticate,
}: ILoginFormProps) => {
  useEffect(() => {
    if (userAuthToken) {
      navigate('/');
    }
  }, []);
  const { register, handleSubmit, formState } = useForm();

  const onSubmit = (data: any) => {
    const { username, password }: IAuthUser = data;
    userAuthenticate({ username, password });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Box boxShadow={10} borderRadius={4} padding={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              inputRef={register({ required: true })}
              id="username"
              label="Username"
              name="username"
              error={formState.errors.username && true}
              helperText={formState.errors.username && 'Username is required.'}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register({ required: true })}
              id="password"
              label="Password"
              name="password"
              error={formState.errors.password && true}
              helperText={formState.errors.password && 'Password is required.'}
              type="password"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <Button type="submit" color="primary" variant="contained">
              Login
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default LoginForm;
