import React from 'react';
import { Grid } from '@material-ui/core';

import SEO from '../../components/SEO';
import Layout from '../../layout/AppLayout';
import { ContentContainer } from '../../styles/styled';
import LoginForm from '../../components/Form/LoginForm';

const Admin = () => {
  return (
    <Layout>
      <SEO title="Admin" />
      <ContentContainer>
        <Grid container justify="center">
          <Grid item xs={12} md={6} lg={4}>
            <LoginForm />
          </Grid>
        </Grid>
      </ContentContainer>
    </Layout>
  );
};

export default Admin;
